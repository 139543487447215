/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const promotionSliderSX: SxProps<Theme> = (theme) => ({
	padding: 0,
	'& .carousel__back-button svg, & .carousel__next-button svg': {
		color: '#fff',
	},
});
