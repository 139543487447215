/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { headerNavBarDropMenuSX } from '@/components/content/Header/styles/navBar/dropMenu';
import { wacoalHeaderNavBarItemSX } from '@/components/content/Header/styles/navBar/wacoalItem';
import { PageLink } from '@/data/Navigation';
import { ID } from '@/data/types/Basic';
// import { Link } from '@/styles/Wacoal/Link';
import { REG_EX } from '@/utils/address';
import { Box, Button, ClickAwayListener, Stack, Tooltip } from '@mui/material';
import _debounce from 'lodash/debounce';
import React, { Dispatch, KeyboardEvent, SetStateAction, useMemo, useRef } from 'react';

type Props = {
	tree: PageLink[];
	label: string;
	categoryId?: string;
	isPageScrolled?: boolean;
	isHomepage?: boolean;
	isBtemptd: boolean;
	id: ID;
	href: string | undefined;
	isOpen: boolean;
	openMenuId: string | undefined;
	setOpenMenuId: Dispatch<SetStateAction<string | undefined>>;
};

export const WacoalHeaderNavBarDropMenu = React.forwardRef(
	({
		tree,
		label,
		categoryId,
		isPageScrolled = false,
		isHomepage = false,
		isBtemptd,
		id,
		href,
		isOpen,
		openMenuId,
		setOpenMenuId,
		...props
	}: Props) => {
		const buttonRef = useRef<HTMLButtonElement>(null);

		const { categoryListEspotName, menuEspotName } = useMemo(
			() => ({
				categoryListEspotName: `React_${categoryId}_Menu_CategoryList`,
				menuEspotName: `React_${categoryId}_Menu_Espot`,
			}),
			[categoryId]
		);

		const menuId = useMemo(
			() => `categoryList-${label.toLowerCase().replace(REG_EX.ONLY_STRING, '')}`,
			[label]
		);

		// show/hide menu on enter/spacebar keydown events
		const handleButtonKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
			if (e.code === 'Enter' || e.code === 'Space') {
				// toggle open or closed
				setOpenMenuId((openMenuId) => (openMenuId === categoryId ? undefined : categoryId));
			}
		};

		const handleClose = () => {
			setOpenMenuId(undefined);
		};

		const handleOpen = () => {
			setOpenMenuId(categoryId);
		};

		const handleCloseEvent = (e: Event | React.SyntheticEvent<Element, Event>) => {
			if ('code' in e && e.code === 'Escape') {
				// return focus to button
				buttonRef?.current?.focus();
			}
			handleClose();
		};

		// close menu after 1 second
		const debouncedHandleMouseLeave = _debounce(
			() => setOpenMenuId((openMenuId) => (openMenuId === categoryId ? undefined : openMenuId)),
			1000
		);
		const handleOnMouseEnter = () => {
			handleOpen();
			debouncedHandleMouseLeave.cancel();
		};

		// close the current menu
		const handleClickAway = () =>
			setOpenMenuId((openMenuId) => (openMenuId === categoryId ? undefined : openMenuId));

		console.log('wohoo', handleClickAway);

		return (
			<ClickAwayListener onClickAway={handleClickAway}>
				<Stack
					component="li"
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={debouncedHandleMouseLeave}
				>
					<Tooltip
						placement="bottom-start"
						open={isOpen}
						onClose={handleCloseEvent}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						TransitionProps={{ timeout: 0 }}
						componentsProps={{
							popper: {
								sx: { width: '100%' },
								disablePortal: true,
								id: menuId,
							},
							tooltip: {
								sx: headerNavBarDropMenuSX,
							},
						}}
						title={
							<Stack direction="row" justifyContent="space-between">
								<Box sx={{ padding: '14px 0 6px', flex: 1 }}>
									<ContentRecommendation
										key={categoryListEspotName}
										id={id}
										properties={{ emsName: categoryListEspotName }}
									/>
								</Box>
								<Box
									sx={{
										width: '330px',
										'> *': {
											padding: '0 4px 0px 25px',
											marginTop: '14px',
											borderLeft: '1px solid #eaeaea',
										},
									}}
								>
									<ContentRecommendation
										key={menuEspotName}
										id={id}
										properties={{ emsName: menuEspotName }}
									/>
								</Box>
							</Stack>
						}
					>
						<Box onMouseEnter={handleOpen} onTouchStart={handleClose} sx={{ height: '100%' }}>
							<Button
								component="button"
								ref={buttonRef}
								href={href}
								{...props}
								sx={wacoalHeaderNavBarItemSX(isPageScrolled, !isBtemptd, isHomepage, isOpen)}
								aria-controls={menuId}
								aria-expanded={isOpen}
								onKeyDown={handleButtonKeyDown}
							>
								<Box component="span">{label}</Box>
								{/** <Box
									component="a"
									ref={buttonRef}
									href={href}
									tabIndex={-1}
									aria-hidden="true"
									sx={{ color: 'inherit' }}
								>
									{label}
								</Box> */}
							</Button>
						</Box>
					</Tooltip>
					{/**
					<Button title="+" sx={headerNavBarItemTouchDropButtonSX} onClick={handleToolTip()}>
						<ExpandMoreIcon />
					</Button>
					*/}
				</Stack>
			</ClickAwayListener>
		);
	}
);
