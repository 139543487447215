/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { WacoalHeaderSearch } from '@/components/content/Header/parts/WacoalSearch';
import { mobileSearchBoxSX } from '@/components/content/Header/styles/search/mobileSearchBox';
import { useSearchNavigation } from '@/data/Content/WacoalSearchNavigation';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { ContentContext } from '@/data/context/content';
import { ID } from '@/data/types/Basic';
import { Switch } from '@/utils/switch';
import { Box, ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';

export const MobileSearchDropMenu: FC<{
	isWacoal: boolean;
	isBtemptd: boolean;
	id: ID;
}> = ({ id, isWacoal, isBtemptd }) => {
	const { clearSearchTerm } = useContext(ContentContext) as ReturnType<typeof useSearchNavigation>;
	const router = useNextRouter();
	const [open, setOpen] = useState(false);
	const toggleMenu = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.case('toggle', () => !open)
					.defaultTo(() => !open)
			),
		[]
	);

	useEffect(() => {
		if (!router.asPath) return;
		toggleMenu('close')();
	}, [router.asPath, toggleMenu]);

	const handleCloseButtonClick = () => {
		toggleMenu('close')();
		clearSearchTerm();
	};

	return (
		<ClickAwayListener onClickAway={toggleMenu('close')}>
			<Box sx={mobileSearchBoxSX}>
				<Tooltip
					placement="bottom-start"
					open={open}
					onClose={toggleMenu('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						popper: {
							sx: { width: '100%', top: '100% !important', transform: 'none !important' },
							disablePortal: true,
						},
						tooltip: {
							sx: {
								borderRadius: 0,
								maxWidth: 'none',
								boxShadow: 0,
								margin: '0 !important',
								backgroundColor: 'rgba(255, 255, 255, 0.85)',
								padding: { xs: '0 44px 10px 14px', md: '0 62px 14px 18px' },
							},
						},
					}}
					title={
						<Box role="presentation" sx={{ position: 'relative' }}>
							<WacoalHeaderSearch mobile={true} isWacoal={isWacoal} isBtemptd={isBtemptd} id={id} />
							<IconButton
								aria-label="Close search field"
								onClick={handleCloseButtonClick}
								sx={{
									position: 'absolute',
									right: { xs: '-42px', md: '-52px' },
									top: '5px',
									width: '40px',
									height: '40px',
									cursor: 'pointer',
									'&:before, &:after': {
										position: 'absolute',
										top: '6px',
										right: '20px',
										display: 'block',
										content: '""',
										width: '1px',
										height: '29px',
										backgroundColor: '#000',
										transform: 'rotate(45deg)',
									},
									'&:after': {
										transform: 'rotate(-45deg)',
									},
								}}
							></IconButton>
						</Box>
					}
				>
					<IconButton
						color="primary"
						aria-label="Show search field"
						onClick={toggleMenu('toggle')}
						sx={{
							width: isBtemptd ? '37px' : '36px',
							height: '36px',
							mt: isBtemptd ? { xs: '1px', lg: 0 } : 0,
						}}
					>
						<ContentRecommendation
							key="SearchIcon"
							id={id}
							properties={{ emsName: 'SearchIcon' }}
						/>
					</IconButton>
				</Tooltip>
			</Box>
		</ClickAwayListener>
	);
};
